import domReady from '@roots/sage/client/dom-ready';
import Glide from '@glidejs/glide';
import gsap from 'gsap';

domReady(async () => {
  document.getElementById('productsMenuButton').onclick = function () {
    var menu = document.getElementById('productsMenu');
    if (menu.classList.contains('hidden')) {
      menu.classList.remove('hidden');
    } else {
      menu.classList.add('hidden');
    }
  };

  // Toggle Mobile Menu
  document
    .getElementById('mobileMenuButton')
    .addEventListener('click', function () {
      var menu = document.getElementById('mobileMenu');
      menu.classList.toggle('hidden');
    });

  // Toggle Mobile Products Submenu
  document
    .getElementById('mobileProductsMenuButton')
    .addEventListener('click', function () {
      var submenu = document.getElementById('mobileProductsMenu');
      submenu.classList.toggle('hidden');
    });

  if (document.querySelector('.main-slider')) {
    var hasVideo = document.querySelector('.main-slider video') !== null;

    new Glide('.main-slider', {
      type: 'carousel',
      startAt: 0,
      autoplay: hasVideo ? false : 5000,
    }).mount();
  }

  if (document.querySelector('.other-fruits')) {
    new Glide('.other-fruits', {
      type: 'carousel',
      startAt: 0,
      perView: 7,
      gap: 20,
      peek: {
        before: 0,
        after: 80,
      },
      breakpoints: {
        1024: {
          perView: 3,
          peek: {
            before: 50,
            after: 80,
          },
        },
        600: {
          perView: 2,
          peek: {
            before: 50,
            after: 80,
          },
        },
      },
    }).mount();
  }

  if (document.querySelector('.gallery')) {
    new Glide('.gallery', {
      type: 'carousel',
      startAt: 0,
      perView: 4,
      gap: 0,
      breakpoints: {
        1024: {
          perView: 3,
        },
        600: {
          perView: 1,
        },
      },
    }).mount();
  }

  if (document.querySelectorAll('.scroll-animation').length) {
    // Columna 1
    let scrollContainer1 = document.querySelector('.scroll-animation-1');
    let scrollHeight1 = scrollContainer1.scrollHeight / 2;

    gsap.to('.scroll-animation-1', {
      y: -scrollHeight1,
      ease: 'none',
      repeat: -1,
      duration: 20,
    });

    // Columna 3
    let scrollContainer3 = document.querySelector('.scroll-animation-3');
    let scrollHeight3 = scrollContainer3.scrollHeight / 2;

    gsap.to('.scroll-animation-3', {
      y: -scrollHeight3,
      ease: 'none',
      repeat: -1,
      duration: 25,
    });
  }
});

// HMR activo
if (import.meta.webpackHot) {
  import.meta.webpackHot.accept(console.error);
}
